<template>
  <div
    class="m-login-box h-[calc(100%-66px)] fixed flex flex-col justify-center right-12 w-1/2 lg:w-1/3 xl:w-1/4"
  >
    <div
      class="bg-skin-color1 h-1/5 max-h-40 pt-2 pb-4 relative rounded-t-xl w-full z-0"
    >
      <VentureLogo />
    </div>
    <div
      class="bg-skin-color3/75 backdrop-blur-xl -mt-3 px-10 pb-12 pt-8 rounded-xl shadow-2xl after:block after:absolute after:bottom-0 after:h-32 after:-z-10 after:left-0 after:bg-white/40 after:w-full after:rounded-b-xl before:block before:absolute before:bg-white/10 before:w-full before:h-40 before:top-0 before:left-0 before:bg-gradient-to-b before:from-white before:-z-10 before:opacity-70 before:rounded-t-xl"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import VentureLogo from "@/components/atoms/VentureLogo.vue";

export default {
  name: "LoginBox",
  components: {
    VentureLogo,
  },
};
</script>
