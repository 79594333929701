export default {
  venture: {
    assetsUrl: null,
    clientAreaImage: null,
    companyLogo: null,
    description: "",
    imageCover: null,
    name: "",
    pageTitle: "",
    videoCover: null,
  },
};
