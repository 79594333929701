<template>
  <FullModal
    class="o-expired-modal"
    :onClose="closeModal"
    :open="expiredModalOpen"
  >
    <h3>
      Caro usuário, sua data limite para personalização de unidades expirou.
    </h3>
    <p>
      Por favor, entre em contato com nossa área de relacionamento com clientes
      para mais informações
    </p>
  </FullModal>
</template>

<script>
import FullModal from "./FullModal.vue";
import { inject } from "vue";
import { useCloseModal } from "@/composables/useHandleModals";

export default {
  name: "ExpiredModal",
  components: { FullModal },
  setup() {
    const { setExpiredModalOpen, expiredModalOpen } =
      inject("expiredModalOpen");
    const { closeModal } = useCloseModal(setExpiredModalOpen);

    return {
      closeModal,
      expiredModalOpen,
    };
  },
};
</script>
