<template>
  <div
    class="m-image-cover bg-cover bg-no-repeat bg-center fixed h-screen inset-0 object-cover w-screen"
    :style="`background-image: url('${imageCover}')`"
  ></div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "ImageCover",
  setup() {
    const imageCover = inject("imageCover");

    return {
      imageCover,
    };
  },
};
</script>
