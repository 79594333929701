<template>
  <router-link
    class="a-client-button transition-all ease-linear delay-150 duration-200 group bg-skin-color4 rounded shadow-md !text-white py-2 px-4 text-sm flex items-center hover:scale-105 hover:bg-skin-color1 [&_svg_[fill]:not([fill='none'])]:fill-white [&_svg_[stroke]]:stroke-white [&_svg_*]:transition-all [&_svg_*]:ease-linear [&_svg_*]:delay-150 [&_svg_*]:duration-200"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "ClientButton",
};
</script>
