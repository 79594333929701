<template>
  <div class="o-home-modals">
    <Teleport to="body">
      <WaitingModal />
      <ExpiredModal />
    </Teleport>
  </div>
</template>

<script>
import WaitingModal from "./WaitingModal.vue";
import ExpiredModal from "./ExpiredModal.vue";

export default {
  name: "HomeModals",
  components: { WaitingModal, ExpiredModal },
};
</script>
