<template>
  <div class="o-forgot-password-form text-center">
    <div class="title-container mb-14">
      <h2 class="text-2xl mb-4">Esqueci a senha</h2>
      <p>
        Preencha o campo abaixo com seu CPF ou CNPJ e você receberá um e-mail
        para cadastro de uma nova senha.
      </p>
    </div>
    <form @submit.prevent="submit">
      <div class="form-group form-pass">
        <input
          type="text"
          name="document"
          class="form-control cpfOuCnpj px-6 py-3 rounded-sm text-sm w-full"
          placeholder="CPF ou CNPJ"
          required
          v-model="document"
        />
      </div>
      <div class="absolute bottom-[-26px] left-0 text-center w-full">
        <div class="flex justify-center items-center">
          <router-link
            class="back-button bg-skin-color3 min-w-[38%] h-12 text-white py-3 mr-2 rounded-md shadow-lg [&_svg_[fill]]:fill-white transition-all duration-300 ease-in-out uppercase hover:bg-skin-color1"
            :to="{ name: 'Login' }"
          >
            <inline-svg
              class="inline-block mr-2 -mt-[3px] w-5 transition-all duration-300 ease-in-out"
              :src="require('@/assets/back.svg')"
            />
            Voltar
          </router-link>
          <button
            type="submit"
            class="btn-submit bg-skin-color4 min-w-[48%] py-4 text-white rounded-md [&:is(.sending)_.enter]:hidden [&:is(.sending)_.loader-icon] shadow-lg transition-all duration-300 ease-in-out uppercase hover:bg-skin-color1"
            :class="{ sending }"
            :disabled="sending"
          >
            <inline-svg
              class="enter inline-block mr-5"
              :src="require('@/assets/check.svg')"
            />
            <Image
              src="images/login/loader.svg"
              class="loader-icon hidden w-4 mr-5"
            />
            Enviar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image.vue";
import useForgotPasswordForm from "@/composables/useForgotPasswordForm";
import { inject } from "vue";

export default {
  name: "ForgotPasswordForm",
  setup() {
    const { setSent } = inject("sent");
    const onSubmit = () => setSent(true);
    const { document, sending, submit } = useForgotPasswordForm({ onSubmit });

    return { document, sending, submit };
  },
  components: { Image },
};
</script>
