<template>
  <Image
    alt="Logo Yuny"
    class="a-footer-company-logo h-[14px] lg:h-[12px] lg:mt-[1px]"
    src="images/login/yuny-branco.png"
  />
</template>

<script>
import Image from "@/components/atoms/Image.vue";

export default {
  name: "FooterCompanyLogo",
  components: { Image },
};
</script>
