<template>
  <div class="o-backgroud-cover">
    <VideoCover v-if="videoCover" />
    <ImageCover v-if="!videoCover && imageCover" />
  </div>
</template>

<script>
import ImageCover from "@/components/molecules/ImageCover.vue";
import VideoCover from "@/components/molecules/VideoCover.vue";
import useGetVentureProp from "@/composables/useGetVentureProp";
import { provide } from "vue";

export default {
  name: "BackgroundCover",
  setup() {
    const { imageCover } = useGetVentureProp("imageCover");
    const { videoCover } = useGetVentureProp("videoCover");

    provide("imageCover", imageCover);
    provide("videoCover", videoCover);

    return {
      imageCover,
      videoCover,
    };
  },
  components: { VideoCover, ImageCover },
};
</script>
