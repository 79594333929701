const useRecaptcha = () => {
  const geToken = async (action) => {
    const token = await window.grecaptcha.execute(
      process.env.VUE_APP_RECAPTCHA_KEY,
      { action }
    );

    return { token };
  };

  return { geToken };
};

export default useRecaptcha;
