import preloader from "./preloader";
import properties from "./properties";
import user from "./user";
import venture from "./venture";

export default {
  preloader,
  properties,
  user,
  venture,
};
