<template>
  <Clean class="p-forgot-password h-screen">
    <ForgotPasswordForm v-if="!sent" />
    <ForgotPasswordSuccess v-else />
  </Clean>
</template>

<script>
import Clean from "@/components/templates/Clean.vue";
import ForgotPasswordForm from "@/components/organisms/ForgotPasswordForm.vue";
import ForgotPasswordSuccess from "@/components/organisms/ForgotPasswordSuccess.vue";
import { provide, ref } from "vue";

export default {
  name: "ForgotPassword",
  setup() {
    const sent = ref(false);

    function setSent(state) {
      sent.value = state;
    }

    provide("sent", { setSent });

    return { sent };
  },
  components: { Clean, ForgotPasswordForm, ForgotPasswordSuccess },
};
</script>
