<template>
  <div
    class="sobre-slider-slide bg-white rounded-md shadow-lg w-36 text-center min-h-[16vh] lg:min-h-[20vh] relative !flex flex-col justify-start pt-6 before:bg-gradient-to-l before:from-black before:absolute before:w-4 before:opacity-5 before:h-full before:top-0 before:-right-[3px] before:z-10 before:pointer-events-none"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "CardComponent",
};
</script>
