<template>
  <a
    class="a-footer-link hover:underline"
    rel="noopener noreferrer"
    target="_blank"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: "FooterLink",
};
</script>
