<template>
  <Backdrop class="m-modal-page !z-20 !visible !bottom-0 before:opacity-100">
    <div
      class="absolute pointer-events-auto top-[50%] left-[50%] -translate-y-[51%] lg:translate-y-[-50.8%] translate-x-[-50%] xl:translate-x-[-43%] w-[94vw] xl:w-[80vw] lg:h-[84vh] bg-[#EBEBEB] rounded-xl shadow-xl"
      :class="modalClass"
    >
      <BackButton
        v-if="showBackButton"
        class="z-30"
        @click="$router.push({ name: 'Home' })"
      />
      <div class="rounded-xl overflow-hidden h-full lg:pb-0">
        <div
          class="h-full flex w-full flex-col-reverse lg:flex-row lg:min-h-full lg:max-h-full"
        >
          <div class="w-full h-[30vh] lg:!w-[42%] lg:h-full">
            <img
              :alt="`Perspectiva de ${name}`"
              class="object-cover w-full h-full"
              :src="clientAreaImage"
            />
          </div>
          <div class="w-full relative flex md:pr-8 lg:w-[58%]">
            <div
              class="pointer-events-none absolute z-20 top-0 left-5 lg:left-8 p-4 pl-0 text-[#828281]"
              :class="{
                'lg:left-[274px] xl:left-[286px]': isHomePage && haveProperties,
                flex: isAdmin,
              }"
            >
              <a
                v-if="isAdmin"
                :href="adminUrl"
                target="_blank"
                class="mr-5 text-red-600 underline"
              >
                <span class="pointer-events-auto">Admin</span>
              </a>
              <div>
                <Image
                  src="images/login/icons/cliente.svg"
                  class="w-6 mr-1 inline-block -mt-1"
                />
                <span class="pointer-events-auto">Área do cliente</span>
              </div>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </Backdrop>
</template>

<script>
import Backdrop from "@/components/atoms/Backdrop.vue";
import BackButton from "@/components/atoms/BackButton.vue";
import Image from "@/components/atoms/Image.vue";
import { useRoute } from "vue-router";
import { computed, inject, ref, watch } from "vue";
import { useStore } from "vuex";
import useGetVentureProp from "@/composables/useGetVentureProp";

export default {
  name: "ModalPage",
  props: {
    modalClass: {
      type: String,
      required: false,
      default: "",
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { Backdrop, BackButton, Image },
  setup() {
    const route = useRoute();
    const { getters } = useStore();
    const { clientAreaImage } = useGetVentureProp("clientAreaImage");
    const { name } = useGetVentureProp("name");
    const properties = computed(() => getters["properties/properties"]);
    const isAdmin = computed(() => getters["user/isAdmin"]);
    const haveProperties = ref(properties.value.length);
    const isHomePage = ref(route.name === "Home");
    const assetsUrl = inject("assetsUrl");
    const adminUrl = `${assetsUrl.value}/admin`;

    watch(properties, () => (haveProperties.value = properties.value.length));
    watch(
      () => route.name,
      () => (isHomePage.value = route.name === "Home")
    );

    return {
      adminUrl,
      clientAreaImage,
      haveProperties,
      isAdmin,
      isHomePage,
      name,
    };
  },
};
</script>
