<template>
  <Backdrop class="m-modal">
    <div
      class="font-['Helvetica_Neue'] absolute backdrop-blur-lg top-[50%] left-[50%] translate-y-[-53.5%] translate-x-[-50%] bg-white/[0.85] rounded-xl shadow-xl after:block after:absolute after:bottom-0 after:h-24 after:-z-10 after:left-0 after:bg-white/40 after:w-full after:rounded-b-xl"
      :class="modalClass"
    >
      <BackButton @click="closeModal" />
      <slot />
    </div>
  </Backdrop>
</template>

<script>
import BackButton from "@/components/atoms/BackButton.vue";
import Backdrop from "@/components/atoms/Backdrop.vue";
import { inject } from "vue";

export default {
  name: "ModalComponent",
  components: { Backdrop, BackButton },
  props: {
    modalClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const closeModal = inject("closeModal");

    return { closeModal };
  },
};
</script>
