<template>
  <component
    class="a-action-button transition-all ease-linear delay-150 duration-200 bg-white shadow-lg px-3 pt-3 pb-4 rounded-lg absolute -bottom-4 left-[50%] translate-x-[-50%] text-xs w-4/5 text-[#888888] text-center flex justify-center items-center overflow-hidden before:absolute before:left-0 before:bottom-0 before:h-[5px] before:z-10 before:w-full before:transition-all before:ease-linear before:delay-150 before:duration-200 hover:before:h-full hover:before:bg-skin-color4 hover:text-white hover:scale-105 [&_svg_*]:transition-all [&_svg_*]:ease-linear [&_svg_*]:delay-150 [&_svg_*]:duration-200 [&:hover_svg_[fill]:not([fill='none'])]:fill-white [&:hover_svg_[stroke]]:stroke-white"
    :is="is"
  >
    <span class="z-20 flex justify-center items-center">
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    is: {
      type: String,
      required: false,
      default: "a",
    },
  },
};
</script>
