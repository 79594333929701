import Home from "@/components/pages/customizer/Home.vue";
import CustomizerIndex from "@/components/pages/customizer/Index.vue";
import Login from "@/components/pages/Login.vue";
import ForgotPassword from "@/components/pages/ForgotPassword.vue";

export default [
  {
    path: "/",
    component: CustomizerIndex,
    children: [
      {
        alias: "/",
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/cadastro/senha/editar",
        name: "ChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "changepassword" */ "@/components/pages/customizer/ChangePassword.vue"
          ),
      },
      {
        path: "/cadastro/editar",
        name: "RegisterChange",
        component: () =>
          import(
            /* webpackChunkName: "registerchange" */ "@/components/pages/customizer/RegisterChange.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/esqueci-a-senha",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
];
