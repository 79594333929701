import api from "@/helpers/api";

export default async ({ commit }) => {
  try {
    const {
      data: { data },
    } = await api.get("v1/venture/2");

    if (data) {
      commit("SET_VENTURE", data);
    }
  } catch (error) {
    console.error(error);
  }
};
