<template>
  <footer
    class="o-footer backdrop-blur bottom-0 bg-skin-color1/80 fixed flex items-center justify-center py-4 w-full z-[3000] lg:h-[34px] lg:py-1 lg:px-3"
  >
    <MobileFooter />
    <DesktopFooter />
  </footer>
</template>

<script>
import DesktopFooter from "./DesktopFooter.vue";
import MobileFooter from "./MobileFooter.vue";

export default {
  name: "FooterComponent",
  components: {
    DesktopFooter,
    MobileFooter,
  },
};
</script>
