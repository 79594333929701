<template>
  <ActionButton
    v-if="open && completed"
    :href="link"
    class="before:bg-[#00a597]"
  >
    <inline-svg
      class="inline-block mr-2 w-4 transition-all ease-linear delay-150 duration-200"
      :src="require('@/assets/zoom.svg')"
    />
    <b>Verificar</b>
  </ActionButton>
  <ActionButton
    v-if="open && started && !completed"
    :href="link"
    class="before:bg-skin-color4"
  >
    <inline-svg
      class="inline-block mr-2 w-4 transition-all ease-linear delay-150 duration-200"
      :src="require('@/assets/continue.svg')"
    />
    <b>Continuar</b>
  </ActionButton>
  <ActionButton
    v-if="open && !started"
    :href="link"
    class="before:bg-[#6BBBE5]"
  >
    <inline-svg
      class="inline-block mr-2 w-4 transition-all ease-linear delay-150 duration-200"
      :src="require('@/assets/continue.svg')"
    />
    <b>Iniciar</b>
  </ActionButton>
  <ActionButton
    v-if="!open && response === 'end'"
    type="button"
    class="before:bg-[#767676]"
    is="button"
    @click="() => setExpiredModalOpen(true)"
  >
    <inline-svg
      class="inline-block mr-2 w-4 transition-all ease-linear delay-150 duration-200"
      :src="require('@/assets/traffic-signal.svg')"
    />
    <b>Encerrada</b>
  </ActionButton>
  <ActionButton
    v-if="!open && response !== 'end'"
    type="button"
    class="before:bg-[#D96262]"
    is="button"
    @click="() => setWaitingModalOpen(true)"
  >
    <inline-svg
      class="inline-block mr-2 w-4 transition-all ease-linear delay-150 duration-200"
      :src="require('@/assets/time-left.svg')"
    />
    <b>Aguarde</b>
  </ActionButton>
</template>

<script>
import { inject } from "vue";
import ActionButton from "@/components/atoms/ActionButton.vue";

export default {
  name: "PropertyActions",
  setup() {
    const { setWaitingModalOpen } = inject("waitingModalOpen");
    const { setExpiredModalOpen } = inject("expiredModalOpen");
    const completed = inject("completed");
    const link = inject("link");
    const open = inject("open");
    const response = inject("response");
    const started = inject("started");

    return {
      completed,
      link,
      open,
      response,
      setExpiredModalOpen,
      setWaitingModalOpen,
      started,
    };
  },
  components: { ActionButton },
};
</script>
