<template>
  <Notification />
  <router-view />
</template>

<script>
import Notification from "./components/molecules/Notification.vue";
import useGetAssetsUrl from "@/composables/useGetAssetsUrl";
import useGetVenture from "@/composables/useGetVenture";
import useHandleModals from "@/composables/useHandleModals";
import useHandleNotifications from "@/composables/useHandleNotifications";
import useRefreshToken from "@/composables/useRefreshToken";
import useSetPageMeta from "@/composables/useSetPageMeta";

export default {
  name: "VisionApp",
  setup() {
    const { venture } = useGetVenture();
    const { setNotification } = useHandleNotifications();

    useSetPageMeta({ venture });
    useGetAssetsUrl({ venture });
    useHandleModals();
    useRefreshToken({ setNotification });
  },
  components: { Notification },
};
</script>

<style lang="postcss">
@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  src: url("./assets/fonts/HelveticaNeue.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeue.woff") format("woff");
}
</style>
