export default {
  tokenExpiresAt: (state) => state.auth.tokenExpiresAt,
  email: (state) => state.user.email,
  isAdmin: (state) => state.user.role_id === 1,
  name: (state) => state.user.name,
  phone: (state) => state.user.phone,
  token: (state) => state.auth.token,
  user: (state) => state.user,
  userId: (state) => state.user.id,
};
