<template>
  <div class="o-login-form">
    <div class="mb-8">
      <div class="mb-5">
        <img class="mx-auto my-0 w-32" :src="companyLogo" />
      </div>
      <div
        v-if="showDescription"
        class="px-2 text-center text-base leading-tight [&_p]:mb-3"
        v-html="description"
      />
    </div>
    <form method="POST" @submit.prevent="submit">
      <div class="mb-2">
        <input
          type="text"
          name="cpf"
          :id="`cpf-${Math.random(0, 99)}`"
          class="px-6 py-2 rounded-sm text-sm w-full"
          placeholder="CPF ou CNPJ"
          required
          v-model="document"
        />
      </div>
      <div>
        <input
          type="password"
          name="password"
          placeholder="Senha"
          class="px-6 py-2 rounded-sm text-sm w-full"
          required
          v-model="password"
        />
      </div>
      <ul class="text-center">
        <li>
          <router-link
            class="text-xs text-skin-color2 transition-all duration-300 ease-in-out hover:text-skin-color1 hover:underline"
            :to="{ name: 'ForgotPassword' }"
          >
            <i>Esqueci minha senha</i>
          </router-link>
        </li>
      </ul>
      <div
        class="absolute bottom-[-26px] form-login--submit left-0 text-center w-full"
      >
        <button
          type="submit"
          class="[&:is(.sending)]:pointer-events-none [&:is(.sending)]:bg-[#bbb] [&:is(.sending)_.enter]:hidden [&:is(.sending)_.loader-icon]:inline-block [&_svg_[fill]]:fill-white bg-skin-color4 min-w-[200px] py-3 rounded-md shadow-lg transition-all duration-300 ease-in-out uppercase hover:bg-skin-color1 text-white"
          :class="{ sending }"
          :disabled="sending"
        >
          <inline-svg
            class="enter inline-block mr-5 w-5"
            :src="require('@/assets/enter.svg')"
          />
          <Image
            src="images/login/loader.svg"
            class="loader-icon hidden w-5 mr-5"
          />
          Acessar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image.vue";
import useGetVentureProp from "@/composables/useGetVentureProp";
import useLoginForm from "@/composables/useLoginForm";

export default {
  name: "LoginForm",
  props: {
    showDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { companyLogo } = useGetVentureProp("companyLogo");
    const { description } = useGetVentureProp("description");
    const { document, password, sending, submit } = useLoginForm();

    return {
      companyLogo,
      description,
      document,
      password,
      sending,
      submit,
    };
  },
  components: { Image },
};
</script>
