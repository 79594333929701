<template>
  <div class="m-video-cover">
    <video
      id="background-video"
      class="fixed h-screen inset-0 object-cover w-screen"
      autoplay
      loop
      muted
    >
      <source :src="videoCover" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "VideoCover",
  setup() {
    const videoCover = inject("videoCover");

    return {
      videoCover,
    };
  },
};
</script>
