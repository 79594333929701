import { isAfter, parseISO } from "date-fns";
import { computed, onMounted, provide, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const useRefreshToken = ({ setNotification }) => {
  const { getters } = useStore();
  const route = useRoute();
  const tokenExpiresAt = computed(() => getters["user/tokenExpiresAt"]);
  const needsRefresh = ref(false);

  function setNeedsRefresh(state) {
    needsRefresh.value = state;
  }

  function checkTokenExpiration() {
    const now = Date.now();

    if (
      tokenExpiresAt.value &&
      isAfter(now, parseISO(tokenExpiresAt.value)) &&
      !needsRefresh.value
    ) {
      if (route.name !== "Login") {
        setNeedsRefresh(true);
      }

      setNotification({
        message: "Sua sessão expirou. Por favor, faça login novamente.",
      });
    }
  }

  function startListeners() {
    window.addEventListener("click", checkTokenExpiration, false);
    window.addEventListener("focus", checkTokenExpiration, false);
  }

  onMounted(startListeners);

  watch(() => route.name, checkTokenExpiration);

  provide("needsRefresh", { needsRefresh, setNeedsRefresh });
};

export default useRefreshToken;
