import api from "@/helpers/api";

export default async ({ commit }, payload) => {
  try {
    const { data } = await api.post("auth/login", payload);

    if (data) {
      commit("SET_AUTH", data);
    }
  } catch (message) {
    console.error(message);

    return Promise.reject(message);
  }
};
