<template>
  <main class="t-default">
    <BackgroundCover />
    <main>
      <slot />
    </main>
    <Footer />
    <Modals />
  </main>
</template>

<script>
import BackgroundCover from "@/components/organisms/BackgroundCover.vue";
import Footer from "@/components/organisms/Footer.vue";
import Modals from "@/components/organisms/Modals.vue";

export default {
  name: "DefaultTemplate",
  components: { BackgroundCover, Footer, Modals },
};
</script>
