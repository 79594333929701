<template>
  <Clean class="p-login h-screen">
    <LoginForm showDescription />
  </Clean>
</template>

<script>
import Clean from "@/components/templates/Clean.vue";
import LoginForm from "@/components/organisms/LoginForm.vue";

export default {
  name: "LoginPage",
  components: { Clean, LoginForm },
};
</script>
