<template>
  <li
    class="o-property bg-white rounded-md shadow-lg mb-10 relative min-w-[214px] mr-6 lg:mr-0 lg:last:mb-14 inline-block lg:block first:ml-5 lg:first:ml-0 [&:nth-last-of-type(2)]:mr-5 lg:[&:nth-last-of-type(2)]:mr-0"
  >
    <div class="flex justify-center items-center px-4 py-6">
      <div class="text-center text-[#737372]">
        <h6 class="uppercase font-bold mb-1">Unidade {{ unit }}</h6>
        <div class="-mt-1 text-xs">{{ footage }}m<sup>2</sup></div>
      </div>
    </div>
    <div class="bg-[#EDEDED] px-4 pt-4 pb-10 rounded-b-md">
      <div v-if="end" class="text-center text-[#989898] text-xs">
        <div class="flex items-center justify-center w-full">
          <inline-svg class="mr-2" :src="require('@/assets/calendar.svg')" />
          <span>Prazo para Personalização</span>
        </div>
        <div>
          {{ end }}
        </div>
      </div>
      <PropertyActions />
    </div>
  </li>
</template>

<script>
import { provide } from "vue";
import PropertyActions from "./PropertyActions.vue";

export default {
  name: "PropertyComponent",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const {
      property: {
        footage,
        id,
        link,
        order: { completed, started },
        schedule: {
          dates: { end },
          open,
          response,
        },
        unit,
      },
    } = props;
    provide("completed", completed);
    provide("link", link);
    provide("open", open);
    provide("response", response);
    provide("started", started);
    return {
      footage,
      id,
      completed,
      link,
      started,
      end,
      open,
      response,
      unit,
    };
  },
  components: { PropertyActions },
};
</script>
