<template>
  <img
    class="a-venture-logo object-contain w-full h-full"
    :src="logo"
    :alt="alt"
  />
</template>

<script>
import useGetVentureProp from "@/composables/useGetVentureProp";

export default {
  name: "VentureLogo",
  setup() {
    const { logo } = useGetVentureProp("logo");
    const { name } = useGetVentureProp("name");

    return {
      alt: name,
      logo,
    };
  },
};
</script>
