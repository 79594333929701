import { provide, ref, watch } from "vue";

const useGetAssetsUrl = ({ venture }) => {
  const assetsUrl = ref("");

  watch(venture, () => (assetsUrl.value = venture.value.assetsUrl));

  provide("assetsUrl", assetsUrl);
};

export default useGetAssetsUrl;
