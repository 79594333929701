export default {
  auth: { token: null },
  user: {
    cpf: "",
    email: "",
    id: null,
    name: "",
    phone: null,
    role_id: null,
  },
};
