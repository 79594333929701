import useRecaptcha from "@/composables/useRecaptcha";
import api from "@/helpers/api";
import { ref } from "vue";

const useContactForm = () => {
  const { geToken } = useRecaptcha();
  const email = ref("");
  const name = ref("");
  const message = ref("");
  const sending = ref(false);
  const statusMessage = ref("");

  async function submit() {
    sending.value = true;

    const { token } = await geToken("contato");

    api
      .post("v1/contact-us", {
        email: email.value,
        message: message.value,
        name: name.value,
        token,
      })
      .then(
        ({
          data: {
            data: { message: returnMessage, status },
          },
        }) => {
          statusMessage.value = returnMessage;

          if (status) {
            email.value = "";
            message.value = "";
            name.value = "";
          }
        }
      )
      .catch((error) => {
        statusMessage.value =
          "Erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.";

        console.error(error);
      })
      .finally(() => {
        sending.value = false;

        setTimeout(() => (statusMessage.value = ""), 6000);
      });
  }

  return {
    email,
    message,
    name,
    sending,
    statusMessage,
    submit,
  };
};

export default useContactForm;
