<template>
  <Modal
    class="o-contact-modal"
    id="contact-modal"
    modalClass="w-3/4 h-3/4 translate-y-[-58%] lg:translate-y-[-53%]"
  >
    <div class="flex flex-col-reverse h-full text-center lg:flex-row">
      <div class="relative grow pt-6 pb-40 px-14 lg:py-12 lg:px-16 xl:px-28">
        <h2 class="text-3xl text-[#737372] mb-1 font-thin">Fale Conosco</h2>
        <p class="text-[#737372] text-lg mb-2">Preencha o formulário abaixo:</p>
        <form method="POST" @submit.prevent="submit">
          <div class="mb-1" id="nameGroup">
            <input
              type="text"
              name="name"
              placeholder="Nome"
              class="rounded-[4px] px-6 py-2 text-sm w-full"
              required
              v-model="name"
            />
          </div>
          <div class="mb-1" id="emailGroup">
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              class="rounded-[4px] px-6 py-2 text-sm w-full"
              required
              v-model="email"
            />
          </div>
          <div class="mb-2 h-[10vh]" id="messageGroup">
            <textarea
              name="message"
              class="message px-6 py-2 rounded-[4px] text-sm w-full h-full"
              placeholder="A sua mensagem"
              v-model="message"
            ></textarea>
          </div>
          <button
            type="submit"
            class="flex items-center justify-center [&:is(.sending)]:pointer-events-none [&:is(.sending)]:bg-[#bbb] [&:is(.sending)_.mail]:hidden [&:is(.sending)_.loader-icon]:inline-block uppercase w-full py-2 text-sm text-white font-medium bg-skin-color4 rounded-[4px] hover:shadow-lg hover:scale-105 transition-all duration-300 ease-in-out tracking-[8px] hover:bg-skin-color1"
            :class="{ sending }"
            :disabled="sending"
          >
            <Image
              src="images/login/sendmail.svg"
              class="mail w-7 inline-block mr-2"
            />
            <Image
              src="images/login/loader.svg"
              class="loader-icon w-7 hidden mr-2"
            />
            enviar
          </button>
        </form>
        <div class="w-full">
          <div class="scale-50 -translate-x-[25%]">
            <div id="inline-badge"></div>
          </div>
        </div>
        <div class="contact-box absolute w-full bottom-0 left-0">
          <p class="text-[#737372] text-base mb-2">Ou entre em contato:</p>
          <div
            class="text-white text-center py-3 tracking-wider bg-skin-color1 rounded-bl-xl"
          >
            <ul>
              <li class="mb-2 font-thin text-sm">
                <Image
                  src="images/login/icons/support2.svg"
                  class="w-5 inline-block mr-1"
                />
                (11) 3198-2928
              </li>
              <li class="font-thin text-sm">
                <Image
                  src="images/interface/mail.svg"
                  class="w-5 inline-block mr-1"
                />
                atendimento@155jeronimo.com.br
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center w-full bg-center rounded-r-xl bg-no-repeat bg-cover h-[36%] lg:h-full lg:w-[46%]"
        :style="style"
      >
        <transition>
          <div
            v-if="statusMessage"
            class="rounded-md bg-skin-color2/75 text-white shadow-md p-4 max-w-[70%] backdrop-blur lg:text-lg"
          >
            {{ statusMessage }}
          </div>
        </transition>
      </div>
    </div>
  </Modal>
</template>

<script>
import Image from "@/components/atoms/Image.vue";
import Modal from "@/components/molecules/Modal.vue";
import { useCloseModal } from "@/composables/useHandleModals";
import useContactForm from "@/composables/useContactForm";
import { inject, provide } from "vue";
import useGetPerspective from "@/composables/useGetPerspective";

export default {
  name: "ContactModal",
  components: { Modal, Image },
  setup() {
    const { setContactModalOpen } = inject("contactModalOpen");
    const { closeModal } = useCloseModal(setContactModalOpen);
    const { email, message, name, sending, statusMessage, submit } =
      useContactForm();
    const { style } = useGetPerspective("fale-conosco.jpg");

    provide("closeModal", closeModal);

    return { email, message, name, sending, statusMessage, style, submit };
  },
};
</script>
