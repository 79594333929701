import { provide, ref } from "vue";

/**@todo Dinamizar setters */
const useHandleModals = () => {
  const aboutModalOpen = ref(false);
  const contactModalOpen = ref(false);
  const expiredModalOpen = ref(false);
  const notesModalOpen = ref(false);
  const waitingModalOpen = ref(false);

  function setAboutModalOpen(state) {
    aboutModalOpen.value = state;
  }

  function setContactModalOpen(state) {
    contactModalOpen.value = state;
  }

  function setExpiredModalOpen(state) {
    expiredModalOpen.value = state;
  }

  function setNotesModalOpen(state) {
    notesModalOpen.value = state;
  }

  function setWaitingModalOpen(state) {
    waitingModalOpen.value = state;
  }

  provide("aboutModalOpen", { aboutModalOpen, setAboutModalOpen });
  provide("contactModalOpen", { contactModalOpen, setContactModalOpen });
  provide("expiredModalOpen", { expiredModalOpen, setExpiredModalOpen });
  provide("waitingModalOpen", { waitingModalOpen, setWaitingModalOpen });
  provide("notesModalOpen", { notesModalOpen, setNotesModalOpen });
};

export const useCloseModal = (setter) => ({ closeModal: () => setter(false) });

export default useHandleModals;
