<template>
  <Default class="p-home h-screen">
    <header
      class="flex justify-between lg:mr-5 xl:mr-6 2xl:mr-7 z-[10000] relative pointer-events-none"
    >
      <div class="w-[300px] lg:w-[380px] h-[108px] flex">
        <div
          class="w-[154px] h-full bg-skin-color1 text-center flex items-center ml-5 xl:ml-6 2xl:ml-7 relative rounded-b-xl pointer-events-auto shadow-md"
        >
          <VentureLogo class="h-[86%]" />
        </div>
      </div>
      <LogoutButton />
    </header>
    <ModalPage :showBackButton="$route.name !== 'Home'">
      <div
        class="bg-[#EBEBEB] w-full flex flex-col-reverse relative z-10 lg:flex-row"
      >
        <router-view />
      </div>
    </ModalPage>
  </Default>
</template>

<script>
import Default from "@/components/templates/Default.vue";
import VentureLogo from "@/components/atoms/VentureLogo.vue";
import LogoutButton from "@/components/molecules/LogoutButton.vue";
import ModalPage from "@/components/molecules/ModalPage.vue";

export default {
  name: "IndexPage",
  components: {
    Default,
    VentureLogo,
    LogoutButton,
    ModalPage,
  },
};
</script>
