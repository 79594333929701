import api from "@/helpers/api";

export default ({ commit }) => {
  function clear() {
    commit("properties/CLEAR", null, { root: true });
    commit("CLEAR");
  }

  return api.post("auth/logout").then(clear).catch(console.error);
};
