<template>
  <div class="o-properties scrollbar-dynamic lg:-ml-3 lg:min-h-full">
    <ul
      v-if="properties.length"
      class="flex-nowrap flex min-w-full lg:pt-3 lg:h-[84vh] lg:!pr-7 lg:block lg:min-w-0"
    >
      <Property
        v-for="property in properties"
        :key="property.id"
        :property="property"
      />
      <li class="min-w-[1px] lg:min-h-[30px]"></li>
    </ul>
  </div>
</template>

<script>
import Property from "@/components/molecules/Property.vue";
import { inject, onMounted } from "vue";

export default {
  name: "PropertiesComponent",
  setup() {
    const properties = inject("properties");
    const $ = window.$;

    function initScroll() {
      $(".scrollbar-dynamic").scrollbar();
    }

    onMounted(initScroll);

    return { properties };
  },
  components: { Property },
};
</script>
