<template>
  <Default class="t-clean h-screen">
    <transition>
      <LoginBox v-if="description">
        <slot />
      </LoginBox>
    </transition>
  </Default>
</template>

<script>
import Default from "@/components/templates/Default.vue";
import LoginBox from "@/components/molecules/LoginBox.vue";
import useGetVentureProp from "@/composables/useGetVentureProp";

export default {
  name: "CleanTemplate",
  components: {
    Default,
    LoginBox,
  },
  setup() {
    const { description } = useGetVentureProp("description");

    return { description };
  },
};
</script>
