export default {
  CLEAR(state) {
    state.auth = { token: null };
    state.user = {
      cpf: "",
      email: "",
      id: null,
      name: "",
      phone: null,
      role_id: null,
    };
  },
  SET_AUTH(state, auth) {
    const now = Date.now();
    const tokenExpiresAt = new Date(now + auth.expires_in * 1000);
    const authData = { token: auth.access_token, tokenExpiresAt, ...auth };

    delete authData.access_token;

    state.auth = authData;
  },
  SET_USER(state, user) {
    state.user = user;
  },
};
