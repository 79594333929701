import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import modules from "./modules";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

export default createStore({
  modules,
  plugins: [
    createPersistedState({
      paths: ["user", "venture"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
