import { provide, ref } from "vue";

const useHandleNotifications = () => {
  const defaultStatus = "info";
  const notificationIsActive = ref(false);
  const notificationMessage = ref("");
  const notificationStatus = ref(defaultStatus);

  function setNotificationIsActive(state) {
    notificationIsActive.value = state;
  }

  function setNotificationMessage(message) {
    notificationMessage.value = message;
  }

  function setNotificationStatus(status) {
    notificationStatus.value = status;
  }

  function setNotification({ message, status }) {
    setNotificationMessage(message);

    if (status) {
      setNotificationStatus(status);
    }

    setNotificationIsActive(true);

    setTimeout(
      () => setNotificationIsActive(false),
      (Number(process.env.VUE_APP_NOTIFICATION_TIMER) - 1.2) * 1000
    );

    setTimeout(() => {
      setNotificationMessage("");
      setNotificationStatus(defaultStatus);
    }, Number(process.env.VUE_APP_NOTIFICATION_TIMER) * 1000);
  }

  provide("notifications", {
    notificationIsActive,
    notificationMessage,
    notificationStatus,
    setNotification,
  });

  return { setNotification };
};

export default useHandleNotifications;
