import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import InlineSvg from "vue-inline-svg";
import "./assets/tailwind.css";

const visionApp = createApp(App);

visionApp.use(store).use(router);
visionApp.component("inline-svg", InlineSvg);
visionApp.mount("#app");
