import { computed, onMounted, provide } from "vue";
import { useStore } from "vuex";

const useGetVenture = () => {
  const { dispatch, getters } = useStore();
  const venture = computed(() => getters["venture/venture"]);

  function getVenture() {
    return dispatch("venture/GET_VENTURE");
  }

  onMounted(getVenture);

  provide("venture", venture);

  return {
    venture,
  };
};

export default useGetVenture;
