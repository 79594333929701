import { inject, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const useLoginForm = () => {
  const { dispatch, getters } = useStore();
  const router = useRouter();
  const { setNotification } = inject("notifications");
  const token = getters["user/token"];
  const document = ref("");
  const password = ref("");
  const sending = ref(false);

  function submit() {
    sending.value = true;

    dispatch("user/LOGIN", {
      password: password.value,
      cpf: document.value,
    })
      .then(async () => {
        sending.value = true;

        await dispatch("user/GET_USER");

        sending.value = false;

        const haveTokenAndRouteIsNotLogin =
          token && router.currentRoute.value.name !== "Login";

        haveTokenAndRouteIsNotLogin
          ? window.location.reload()
          : router.push({ name: "Home" });
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          let message =
            "Erro ao efetuar login. Por favor, tente novamente mais tarde.";

          if (error && error === "Unauthorized") {
            message = "Dados incorretos. Por favor, confira e tente novamente.";
          }

          setNotification({ message, status: "error" });
        }
      )
      .finally(() => (sending.value = false));
  }

  return {
    document,
    password,
    sending,
    submit,
  };
};

export default useLoginForm;
