import { provide, watch } from "vue";

const useSetPageMeta = ({ venture }) => {
  function setFavicon(icon) {
    document.getElementById("favicon").href = icon;
  }

  function setPageDescription(description) {
    document.getElementById("meta-description").content = description;
  }

  function setPageTitle(title) {
    document.getElementById("page-title").textContent = title;
  }

  watch(venture, () => {
    setFavicon(venture.value.logo);
    setPageDescription(venture.value.description);
    setPageTitle(venture.value.pageTitle);
  });

  provide("setFavicon", setFavicon);
  provide("setPageDescription", setPageDescription);
  provide("setPageTitle", setPageTitle);
};

export default useSetPageMeta;
