<template>
  <Backdrop
    class="m-full-modal before:!bg-skin-color1/95 text-white"
    :class="{ 'z-[10000] !visible !bottom-0 before:opacity-100': open }"
  >
    <button
      type="button"
      class="close absolute top-1 right-5"
      aria-label="Fechar"
      @click="onClose"
    >
      <span class="!text-5xl" aria-hidden="true">&times;</span>
    </button>
    <div class="content flex items-center justify-center h-full">
      <div class="w-auto text-xl inline-block">
        <slot />
      </div>
    </div>
  </Backdrop>
</template>

<script>
import Backdrop from "@/components/atoms/Backdrop.vue";

export default {
  name: "FullModal",
  components: { Backdrop },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
};
</script>
