<template>
  <Properties />
  <ClientArea />
  <HomeModals />
</template>

<script>
import Properties from "@/components/organisms/Properties.vue";
import ClientArea from "@/components/organisms/ClientArea.vue";
import useGetProperties from "@/composables/useGetProperties";
import HomeModals from "@/components/organisms/HomeModals.vue";

export default {
  name: "HomePage",
  components: {
    Properties,
    ClientArea,
    HomeModals,
  },
  setup() {
    useGetProperties();
  },
};
</script>
