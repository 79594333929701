<template>
  <Image
    alt="Logo YunyLab"
    class="a-footer-logo h-[12px] lg:h-[13px] lg:mt-[1px]"
    src="images/login/yunylab.png"
  />
</template>

<script>
import Image from "@/components/atoms/Image.vue";

export default {
  name: "FooterLogo",
  components: { Image },
};
</script>
