import api from "@/helpers/api";

export default ({ commit }) => {
  return api
    .post("auth/me")
    .then(({ data }) => {
      if (data) {
        commit("SET_USER", data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
