<template>
  <img v-if="imgSrc" :src="imgSrc" :alt="alt" class="a-image" />
</template>

<script>
import { inject, ref, watchEffect } from "vue";

export default {
  name: "ImageComponent",
  props: {
    alt: {
      type: String,
      required: false,
      default: "",
    },
    src: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const assetsUrl = inject("assetsUrl");
    const imgSrc = ref(null);

    watchEffect(
      () =>
        (imgSrc.value = assetsUrl.value
          ? `${assetsUrl.value}/${props.src}`
          : imgSrc.value)
    );

    return {
      imgSrc,
    };
  },
};
</script>
