<template>
  <Modal
    class="o-notes-modal"
    id="notes-modal"
    modalClass="px-5 pt-12 w-2/5 h-5/6"
  >
    <div class="px-8">
      <div class="text-[#737372] text-sm">
        <h2 class="text-4xl text-[#737372] mb-5 text-center">Notas gerais</h2>
        <div class="pb-7 h-[66vh] relative">
          <div class="scrollbar-dynamic max-h-[98%]">
            <div class="pr-3">
              <p class="mb-4">
                - No interesse do bom andamento da obra, a Construtora poderá
                substituir os materiais que faltem no mercado na época de sua
                aquisição ou que se tornem inacessíveis desde que mantida a
                mesma qualidade;
              </p>
              <p class="mb-4">
                - As imagens representadas a seguir são meramente ilustrativas e
                referenciais, podendo diferir quanto à textura e coloração dos
                materiais originais e poderão estar invertidas em relação à
                planta;
              </p>
              <p class="mb-4">
                - Granitos e mármores são pedras naturais, podendo sofrer
                variações de tonalidade;
              </p>
              <p class="mb-4">
                - O recorte na bancada da cozinha para instalação do cooktop
                será de responsabilidade do proprietário, após o recebimento de
                sua unidade autônoma e da assembleia de instalação do
                condomínio;
              </p>
              <p class="mb-4">
                - A aquisição e instalação dos assentos sanitários serão de
                responsabilidade do proprietário, após recebimento de sua
                unidade autônoma e da instalação do condomínio;
              </p>
              <p class="mb-4">
                - Dimensões estão sujeitas a variações em decorrência da
                execução e dos acabamentos a serem utilizados.
              </p>
              <p>
                - As plantas da unidade personalizada não indicam a localização
                dos pontos de elétrica, de forma que a VENDEDORA os instalará de
                acordo com o projeto, não podendo o COMPRADOR solicitar nenhuma
                alteração quanto a este item.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/molecules/Modal.vue";
import { useCloseModal } from "@/composables/useHandleModals";
import { inject, onMounted, provide } from "vue";

export default {
  name: "NotesModal",
  components: { Modal },
  setup() {
    const { setNotesModalOpen } = inject("notesModalOpen");
    const { closeModal } = useCloseModal(setNotesModalOpen);
    const $ = window.$;

    function initScroll() {
      $(".scrollbar-dynamic").scrollbar();
    }

    onMounted(initScroll);

    provide("closeModal", closeModal);
  },
};
</script>
