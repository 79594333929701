<template>
  <div
    class="o-client-area pt-14 px-5 pb-10 lg:pl-10 xl:pl-14 lg:pt-8 lg:pb-14 flex-1"
  >
    <div class="flex lg:justify-end w-full h-full lg:flex-col">
      <Greetings>
        <div class="text-[#737372] text-left uppercase font-bold text-base m-0">
          seus dados
        </div>
        <div
          class="text-[#737372] text-left font-normal text-lg mt-0 ml-0 flex"
        >
          <Image src="images/login/icons/email.svg" class="w-6 mr-1" />
          <span>{{ email }}</span>
        </div>
        <div
          v-if="phone"
          class="text-[#737372] text-left font-normal text-lg mt-0 ml-0 flex"
        >
          <Image src="images/login/icons/phone.svg" class="w-6 mr-1" />
          <span>{{ phone }}</span>
        </div>
      </Greetings>
      <div class="lg:w-full">
        <div class="w-full mt-6">
          <nav>
            <ul class="w-60">
              <li class="mb-3">
                <ClientButton :to="{ name: 'RegisterChange' }">
                  <inline-svg
                    class="transition-all ease-linear delay-150 duration-200 inline-block mr-2 w-4"
                    :src="require('@/assets/alert.svg')"
                  />
                  <span>Comunicar ajuste de cadastro</span>
                </ClientButton>
              </li>
              <li class="mb-3">
                <ClientButton :to="{ name: 'ChangePassword' }">
                  <inline-svg
                    class="transition-all ease-linear delay-150 duration-200 inline-block mr-2 w-4"
                    :src="require('@/assets/mudar-senha.svg')"
                  />
                  <span>Alterar minha senha</span>
                </ClientButton>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientButton from "@/components/atoms/ClientButton.vue";
import Greetings from "@/components/molecules/Greetings.vue";
import Image from "@/components/atoms/Image.vue";
import { computed, provide } from "vue";
import { useStore } from "vuex";

export default {
  name: "ClientArea",
  components: { Greetings, ClientButton, Image },
  setup() {
    const { getters } = useStore();
    const email = computed(() => getters["user/email"]);
    const name = computed(() => getters["user/name"]);
    const phone = computed(() => getters["user/phone"]);

    provide("name", name);

    return { email, phone };
  },
};
</script>
