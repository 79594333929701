import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import store from "@/store";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    if (to.meta.noTop) {
      return;
    }

    return {
      top: 0,
    };
  },
});

router.beforeEach((to) => {
  const { getters } = store;
  const isLoggedIn = Boolean(getters["user/token"]);
  const isGoingToLoginRoute = to.name === "Login";
  const isGoingToForgotPasswordRoute = to.name === "ForgotPassword";
  const isNotAllowed =
    !isGoingToForgotPasswordRoute && !isLoggedIn && !isGoingToLoginRoute;

  if (isNotAllowed) {
    return { name: "Login" };
  }
});

export default router;
