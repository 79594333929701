<template>
  <Backdrop
    class="o-refresh-token-modal before:!bg-skin-color1/70 backdrop-blur-md"
  >
    <div class="h-full w-full flex justify-center items-center">
      <LoginBox class="right-auto relative">
        <LoginForm />
      </LoginBox>
    </div>
  </Backdrop>
</template>

<script>
import Backdrop from "@/components/atoms/Backdrop.vue";
import LoginBox from "@/components/molecules/LoginBox.vue";
import LoginForm from "./LoginForm.vue";

export default {
  name: "RefreshTokenModal",
  components: { Backdrop, LoginBox, LoginForm },
};
</script>
