import { inject, ref, watchEffect } from "vue";

const useGetPerspective = (img) => {
  const assetsUrl = inject("assetsUrl");
  const style = ref(null);

  watchEffect(
    () =>
      (style.value = assetsUrl.value
        ? `background-image: url('${assetsUrl.value}/images/ventures/${img}');`
        : null)
  );

  return { style };
};

export default useGetPerspective;
