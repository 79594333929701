<template>
  <div class="confirm-pass-section pb-28">
    <div class="title-container text-center">
      <h2 class="text-2xl mb-4">Solicitação enviada com sucesso</h2>
      <p>
        Caso não receba o e-mail, por favor verifique sua caixa de spam ou entre
        em contato com a nossa central de relacionamento para atualização de
        cadastro.
      </p>
    </div>
  </div>
  <div class="absolute w-full bottom-0 left-0 rounded-b-xl bg-skin-color1">
    <div class="text-white text-center pt-6 pb-10 tracking-wide">
      <ul>
        <li class="mb-1 font-thin text-lg">
          <Image
            src="images/login/icons/support2.svg"
            class="w-[30px] inline-block mr-1"
          />
          (11) 3198-2928
        </li>
        <li class="phone font-['Helvetica_Neue'] font-thin text-lg">
          <Image
            src="images/interface/mail.svg"
            class="w-[30px] inline-block mr-1"
          />
          atendimento@155jeronimo.com.br
        </li>
      </ul>
    </div>
    <div class="absolute block -bottom-6 text-center w-full">
      <router-link
        class="back-button bg-skin-color4 min-w-[38%] h-12 text-white py-3 mr-2 px-4 rounded-md shadow-lg [&_svg_[fill]]:fill-white transition-all duration-300 ease-in-out uppercase hover:bg-skin-color1"
        :to="{ name: 'Login' }"
      >
        <inline-svg
          class="inline-block mr-2 -mt-[3px] w-5 transition-all duration-300 ease-in-out"
          :src="require('@/assets/back.svg')"
        />
        Voltar
      </router-link>
    </div>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image.vue";

export default {
  name: "ForgotPasswordSuccess",
  components: { Image },
};
</script>
