<template>
  <div
    class="o-desktop-footer hidden justify-between items-center w-full lg:flex"
  >
    <div class="flex-auto text-white text-xs tracking-wider">
      <ul class="items-center flex w-full">
        <li class="mr-5">
          <FooterCompanyLogo />
        </li>
        <li class="mr-5">
          <p>
            Todos os direitos reservados
            <span
              class="before:content-['|'] before:inline-block before:w-auto before:h-full"
            >
              by
              <FooterLink
                href="http://www.altamidia.com.br"
                title="Acesse o site da Altamidia"
              >
                Altamidia
              </FooterLink>
            </span>
          </p>
        </li>
        <li class="mr-5">
          <p>
            3D
            <span
              class="before:content-['|'] before:inline-block before:w-auto before:h-full hover:underline"
            >
              <FooterLink
                href="https://visualles.com"
                title="Acesse o site da Visualles"
              >
                Visualles
              </FooterLink>
            </span>
          </p>
        </li>
        <li class="mr-5">
          <FooterLogo />
        </li>
      </ul>
    </div>
    <div class="text-white text-xs">
      <ul class="flex items-center justify-around">
        <li class="mr-4">
          <FooterButton
            alt="Sobre a Plataforma"
            icon="images/login/info.svg"
            @click="() => setAboutModalOpen(true)"
          >
            Sobre a Plataforma
          </FooterButton>
        </li>
        <li class="mr-4">
          <FooterButton
            alt="Notas Gerais"
            icon="images/login/info.svg"
            @click="() => setNotesModalOpen(true)"
          >
            Notas Gerais
          </FooterButton>
        </li>
        <li>
          <FooterButton
            alt="Fale Conosco"
            icon="images/login/support.svg"
            @click="() => setContactModalOpen(true)"
          >
            Fale Conosco
          </FooterButton>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FooterCompanyLogo from "@/components/atoms/FooterCompanyLogo.vue";
import FooterLogo from "@/components/atoms/FooterLogo.vue";
import FooterButton from "@/components/molecules/FooterButton.vue";
import FooterLink from "@/components/atoms/FooterLink.vue";
import useFooter from "@/composables/useFooter";

export default {
  name: "DesktopFooter",
  components: { FooterButton, FooterCompanyLogo, FooterLink, FooterLogo },
  setup() {
    const { setAboutModalOpen, setContactModalOpen, setNotesModalOpen } =
      useFooter();

    return {
      setAboutModalOpen,
      setContactModalOpen,
      setNotesModalOpen,
    };
  },
};
</script>
