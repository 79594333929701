import api from "@/helpers/api";
import { inject, ref } from "vue";

const useForgotPasswordForm = ({ onSubmit }) => {
  const document = ref("");
  const sending = ref(false);
  const { setNotification } = inject("notifications");

  async function submit() {
    sending.value = true;

    api
      .put("v1/new-password", {
        document: document.value,
      })
      .then(
        ({
          data: {
            data: { message: returnMessage, status },
          },
        }) => {
          setNotification({
            message: returnMessage,
          });

          if (status) {
            onSubmit();

            document.value = "";
          }
        }
      )
      .catch((error) => {
        setNotification({
          message:
            "Erro ao enviar solicitação. Por favor, tente novamente mais tarde.",
          status: "error",
        });

        console.error(error);
      })
      .finally(() => (sending.value = false));
  }

  return {
    document,
    sending,
    submit,
  };
};

export default useForgotPasswordForm;
