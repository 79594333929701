<template>
  <div
    class="a-backdrop -bottom-[100vh] -z-[3000] invisible fixed w-screen h-screen left-0 transition-all duration-200 linear before:transition-opacity before:duration-300 before:linear before:bg-black/50 before:opacity-0 before:w-screen before:h-screen before:fixed before:top-0 before:left-0 before:-z-[10] before:pointer-events-none"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "BackdropComponent",
};
</script>
