<template>
  <div class="o-modals">
    <Teleport to="body">
      <AboutModal
        :class="{
          [openClasses]: aboutModalOpen,
        }"
      />
      <ContactModal
        :class="{
          [openClasses]: contactModalOpen,
        }"
      />
      <NotesModal
        :class="{
          [openClasses]: notesModalOpen,
        }"
      />
      <RefreshTokenModal
        :class="{
          [openClasses]: showRefreshTokenModal,
        }"
      />
    </Teleport>
  </div>
</template>

<script>
import AboutModal from "@/components/organisms/AboutModal.vue";
import ContactModal from "@/components/organisms/ContactModal.vue";
import NotesModal from "@/components/organisms/NotesModal.vue";
import RefreshTokenModal from "@/components/organisms/RefreshTokenModal.vue";
import { inject } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ModalsComponent",
  components: { AboutModal, NotesModal, ContactModal, RefreshTokenModal },
  setup() {
    const openClasses = "z-[10000] !visible !bottom-0 before:opacity-100";
    const { aboutModalOpen } = inject("aboutModalOpen");
    const { contactModalOpen } = inject("contactModalOpen");
    const { needsRefresh } = inject("needsRefresh");
    const { notesModalOpen } = inject("notesModalOpen");
    const { name } = useRoute();
    const showRefreshTokenModal =
      needsRefresh.value && name !== "ForgotPassword";

    return {
      aboutModalOpen,
      contactModalOpen,
      notesModalOpen,
      openClasses,
      showRefreshTokenModal,
    };
  },
};
</script>
