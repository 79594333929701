import { inject, ref, watchEffect } from "vue";

const useGetVentureProp = (prop) => {
  const venture = inject("venture");
  const propValue = ref(null);

  watchEffect(() => (propValue.value = venture.value[prop]));

  return {
    [prop]: propValue,
  };
};

export default useGetVentureProp;
