<template>
  <div class="m-logout-button relative z-[10000]">
    <button
      class="fechar group bg-white rounded-b-md flex justify-center pointer-events-auto items-center h-9 w-11 cursor-pointer transition-all ease-linear duration-300 mr-5 lg:mr-0 hover:bg-skin-color4 hover:h-10"
      title="Sair"
      type="button"
      id="logout-button"
      @click="() => toggleConfirm(true)"
    >
      <inline-svg
        class="transition-all ease-in-out duration-300 delay-150 w-6 fill-skin-color2 group-hover:fill-white"
        :src="require('@/assets/logout.svg')"
      />
    </button>
    <div
      class="transition-all ease-in-out duration-300 delay-150 absolute bg-white -z-10 rounded-md py-3 w-44 [&:is(.opacity-0)]:pointer-events-none -right-2 top-[4vh] [&:not(.opacity-0)]:top-[8vh] [&:not(.opacity-0)]:z-[10001] [&:not(.opacity-0)]:pointer-events-auto shadow-lg before:absolute before:right-5 before:-top-4 before:w-0 before:h-0 before:border-solid before:border-b-white before:border-t-transparent before:border-r-transparent before:border-l-transparent before:border-t-0 before:border-r-[10px] before:border-b-[16px] before:border-l-[10px]"
      :class="{ 'opacity-0': !confirmOpen }"
      id="logout-modal"
    >
      <div class="flex flex-col">
        <div class="mb-4 text-center text-[#727272]">Deseja mesmo sair?</div>
        <div class="flex justify-around h-8">
          <button
            class="transition-all ease-in-out duration-300 delay-150 rounded text-[#727272] text-sm bg-slate-200 !py-1 !px-3 hover:text-white hover:bg-[#727272]"
            @click="() => toggleConfirm(false)"
          >
            Não
          </button>
          <button
            class="transition-all ease-in-out duration-300 delay-150 rounded bg-[#727272] text-white p-3 hover:bg-skin-color4 !py-1 !px-3"
            type="button"
            @click="logout"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "LogoutButton",
  setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const confirmOpen = ref(false);

    function toggleConfirm(state) {
      confirmOpen.value = state;
    }

    async function logout() {
      await dispatch("user/LOGOUT");

      router.push({ name: "Login" });
    }

    return { toggleConfirm, confirmOpen, logout };
  },
};
</script>
