import { computed, onMounted, provide } from "vue";
import { useStore } from "vuex";

const useGetProperties = () => {
  const { dispatch, getters } = useStore();
  const userId = computed(() => getters["user/userId"]);
  const properties = computed(() => getters["properties/properties"]);

  function getProperties() {
    return dispatch("properties/GET_PROPERTIES", userId.value);
  }

  onMounted(getProperties);

  provide("properties", properties);
};

export default useGetProperties;
