<template>
  <Teleport to="body">
    <div
      class="m-notification transition-all duration-300 -top-44 ease-in-out fixed z-[10001] left-1/2 -translate-x-1/2 overflow-hidden rounded-md bg-skin-color1/80 text-white shadow-md px-4 pt-2 pb-3 max-w-[70%] backdrop-blur before:h-1 before:w-full before:bottom-0 before:z-10 before:left-1/2 before:-translate-x-1/2 before:absolute lg:max-w-xs xl:max-w-md"
      :class="{
        [statusClass]: statusClass,
        [openClass]: openClass,
        ' before:animate-progress': notificationMessage,
      }"
    >
      <span>
        {{ notificationMessage }}
      </span>
    </div>
  </Teleport>
</template>

<script>
import { computed, inject } from "vue";

export default {
  name: "NotificationComponent",
  setup() {
    const { notificationIsActive, notificationMessage, notificationStatus } =
      inject("notifications");

    const statusClass = computed(() =>
      notificationStatus.value === "info"
        ? "before:bg-skin-color4"
        : "before:bg-red-600"
    );

    const openClass = computed(() =>
      notificationIsActive.value ? "!delay-100 !top-4" : "delay-150"
    );

    return { notificationMessage, openClass, statusClass };
  },
};
</script>
