<template>
  <FullModal
    class="o-waiting-modal"
    :onClose="closeModal"
    :open="waitingModalOpen"
  >
    <h3>
      Caro cliente, a personalização desta unidade ainda não está liberada.
    </h3>
    <p>
      Por favor, entre em contato com nossa área de relacionamento com clientes
      para mais informações.
    </p>
  </FullModal>
</template>

<script>
import FullModal from "./FullModal.vue";
import { inject } from "vue";
import { useCloseModal } from "@/composables/useHandleModals";

export default {
  name: "WaitingModal",
  components: { FullModal },
  setup() {
    const { setWaitingModalOpen, waitingModalOpen } =
      inject("waitingModalOpen");
    const { closeModal } = useCloseModal(setWaitingModalOpen);

    return {
      closeModal,
      waitingModalOpen,
    };
  },
};
</script>
