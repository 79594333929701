<template>
  <div class="m-greetings grow lg:w-full lg:grow-0">
    <p
      v-if="isDay"
      class="text-[#737372] text-left font-normal mb-0 text-3xl ml-0"
    >
      Bom dia,
    </p>
    <p
      v-if="isAfternoon"
      class="text-[#737372] text-left font-normal mb-0 text-3xl ml-0"
    >
      Boa tarde,
    </p>
    <p
      v-if="isNight"
      class="text-[#737372] text-left font-normal mb-0 text-3xl ml-0"
    >
      Boa noite,
    </p>
    <p
      class="text-[#737372] text-left text-3xl lg:text-4xl mt-0 font-bold ml-0"
    >
      {{ name }}
    </p>
    <div class="w-full mt-4">
      <slot />
    </div>
  </div>
</template>

<script>
import { isAfter, isBefore, startOfHour } from "date-fns";
import { inject } from "vue";

export default {
  name: "GreetingsComponent",
  setup() {
    const now = new Date();
    const afternoon = startOfHour(new Date().setHours(12, 0, 0));
    const day = startOfHour(new Date().setHours(6, 0, 0));
    const night = startOfHour(new Date().setHours(18, 0, 0));
    const isAfternoon = isAfter(now, afternoon) && isBefore(now, night);
    const isNight = isAfter(now, night) || isBefore(now, day);
    const isDay = !isAfternoon && !isNight;

    const name = inject("name");

    return { isAfternoon, isDay, isNight, name };
  },
};
</script>
