import { inject } from "vue";

const useFooter = () => {
  const { setAboutModalOpen } = inject("aboutModalOpen");
  const { setContactModalOpen } = inject("contactModalOpen");
  const { setNotesModalOpen } = inject("notesModalOpen");

  return {
    setAboutModalOpen,
    setContactModalOpen,
    setNotesModalOpen,
  };
};

export default useFooter;
