<template>
  <button
    class="a-back-button [&_svg_[fill]]:fill-white absolute right-8 -top-3 bg-skin-color4 w-40 h-12 py-3 rounded-md shadow-lg transition-all duration-300 ease-in-out uppercase z-10 hover:bg-skin-color1 text-white"
    type="button"
  >
    <inline-svg
      class="inline-block mr-2 -mt-[3px] w-5 transition-all duration-300 ease-in-out"
      :src="require('@/assets/back.svg')"
      title="Voltar"
    />
    Voltar
  </button>
</template>

<script>
export default {
  name: "BackButton",
};
</script>
