<template>
  <button
    type="button"
    class="m-footer-button cursor-pointer tracking-wider hover:underline"
  >
    <Image
      class="inline-block mr-1 mt-[-3px] w-[18px]"
      :alt="alt"
      :src="icon"
    />
    <slot />
  </button>
</template>

<script>
import Image from "@/components/atoms/Image.vue";

export default {
  name: "FooterButton",
  components: { Image },
  props: {
    alt: { default: null, type: String },
    icon: { required: true, type: String },
  },
};
</script>
